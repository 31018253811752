<template>
  <div>
    <v-tabs
      background-color="transparent"
      v-model="currentPage"
      active-class="activeTab"
      optional
      height="60px"
      class="hidden-sm-and-down"
    >
      <v-tab v-on:click="toTop" to="/" :ripple="false">
        <span class="tabText">{{ navbarArray[0] }}</span>
      </v-tab>
      <v-tab to="/platform/bazaar" :ripple="false">
        <span class="tabText">{{ navbarArray[1] }}</span>
      </v-tab>

      <v-tab href="https://tectechain.io" :ripple="false">
        <span class="tabText">{{ navbarArray[2] }}</span>
      </v-tab>
      <v-tab to="/#cases" :ripple="false">
        <span class="tabText">{{ navbarArray[3] }}</span>
      </v-tab>
      <v-tab to="/#FAQ" :ripple="false">
        <span class="tabText">{{ navbarArray[4] }}</span>
      </v-tab>
      <v-tab to="/about" :ripple="false">
        <span class="tabText">{{ navbarArray[5] }}</span>
      </v-tab>
      <!-- <v-tab :ripple="false" v-on:click="toEventList">
            <router-link to="/events">{{ $t("createEvent") }}</router-link>
          </v-tab> -->
    </v-tabs>
    <div class="hidden-md-and-up">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn block tile icon v-on="on" style="margin: 0 5px 0 5px; height: 60px">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list class="responsiveMenu">
          <v-list-item v-on:click="toTop" to="/" :ripple="false">
            <span class="tabText">{{ navbarArray[0] }}</span>
          </v-list-item>
          <v-list-item to="/platform/bazaar" :ripple="false">
            <span class="tabText">{{ navbarArray[1] }}</span>
          </v-list-item>
          <v-list-item href="https://tectechain.io" :ripple="false">
            <span class="tabText">{{ navbarArray[2] }}</span>
          </v-list-item>
          <v-list-item to="/#cases" :ripple="false">
            <span class="tabText">{{ navbarArray[3] }}</span>
          </v-list-item>
          <v-list-item to="/#FAQ" :ripple="false">
            <span class="tabText">{{ navbarArray[4] }}</span>
          </v-list-item>
          <v-list-item to="/about" :ripple="false">
            <span class="tabText">{{ navbarArray[5] }}</span>
          </v-list-item>
          <v-list-item to="/login" v-if="!isLogin"
            ><span class="tabText">{{ $t("login") }}</span>
          </v-list-item>
          <v-list-item to="/signup" v-if="!isLogin"
            ><span class="tabText">{{ $t("signup") }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import router from "../../router/index";
var VueScrollTo = require("vue-scrollto");
var options = {
  easing: "ease-in-out",
  lazy: false,
  offset: -60
};
export default {
  methods: {
    async toTop() {
      if (this.$route.name !== "Home") {
        await router.push("/").catch(() => {});
      }
      VueScrollTo.scrollTo("#heroBlock", 500, options);
      this.$store.commit("hideBackDestination");
      this.disableTab();
    },
    async toNews() {
      if (this.$route.name !== "Home") {
        await router.push("/").catch(() => {});
      }
      this.$store.commit("hideBackDestination");
      VueScrollTo.scrollTo("#cases", 500, options);
    },
    async toFAQ() {
      if (this.$route.name !== "Home") {
        await router.push("/").catch(() => {});
      }
      this.$store.commit("hideBackDestination");
      VueScrollTo.scrollTo("#FAQ", 500, options);
    },
    async toFeatures() {
      if (this.$route.name !== "Home") {
        await router.push("/").catch(() => {});
      }
      this.$store.commit("hideBackDestination");
      VueScrollTo.scrollTo("#pricingTable", 500, options);
    },
    async toExploreMore() {
      if (this.$route.name !== "Home") {
        await router.push("/").catch(() => {});
      }
      this.$store.commit("hideBackDestination");
    },
    toEventList() {
      this.$router.push("/events").catch((e) => e);
    },
    disableTab() {
      this.$store.commit("updateCurrentPage", null);
    }
  },
  computed: {
    navbarArray() {
      const content = this.$t("navbar");
      const result = content.replace(/['"]+/g, "").replace(/['“]+/g, "").replace(/['”]+/g, "").split(",");
      return result;
    },
    isLogin() {
      return !!this.$session.get("token");
    },
    currentPage: {
      get() {
        return this.$store.state.landingPageCurrent;
      },
      set(value) {
        this.$store.commit("updateCurrentPage", value);
      }
    }
  }
};
</script>

<style lang="scss">
.tabText {
  text-transform: none !important;
  letter-spacing: normal !important;
  color: #000;
}

.activeTab {
  font-weight: 600;
  margin: 0;
}
.v-tab--active {
  font-weight: 600;
}

.responsiveMenu {
  min-width: 200px;
  .v-btn {
    min-width: 100px;
  }
  .tabText {
    font-size: 14px;
  }
}
</style>
