<template>
  <div>
    <v-btn fab depressed color="primary" style="margin: 4.5px" :height="size" :width="size" @click="redirect(link[0])"
      ><v-icon :size="iconSize">fab fa-facebook</v-icon></v-btn
    >
    <v-btn fab depressed color="primary" style="margin: 4.5px" :height="size" :width="size" @click="redirect(link[1])"
      ><v-icon :size="iconSize">fab fa-twitter</v-icon></v-btn
    >
    <v-btn fab depressed color="primary" style="margin: 4.5px" :height="size" :width="size" @click="redirect(link[2])"
      ><v-icon :size="iconSize">fab fa-instagram</v-icon></v-btn
    >
    <v-btn fab depressed color="primary" style="margin: 4.5px" :height="size" :width="size" @click="redirect(link[3])"
      ><v-icon :size="iconSize">fab fa-youtube</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  data: () => ({
    link: [
      "https://www.facebook.com/Tectechain-101951611757394",
      "https://twitter.com/tectechain",
      "https://www.instagram.com/tectechain/",
      "https://www.youtube.com/channel/UCtaEVTUuOqgTq2bYV3fhpeQ/"
    ]
  }),
  props: {
    size: {
      default: "24"
    }
  },
  methods: {
    redirect: (to) => {
      var windowReference = window.open();
      const getUrl = async (to) => {
        return to;
      };
      getUrl(to).then(function (url) {
        windowReference.location = url;
      });
    }
  },
  computed: {
    iconSize() {
      return this.$screen.width < 600 ? 18 : 12;
    }
  }
};
</script>
