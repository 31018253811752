<template>
  <div>
    <v-menu :offset-y="offsetY" :offset-x="offsetX" close-on-click rounded="0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn tile text block height="60px" v-bind="attrs" v-on="on" :loading="loading">
          <flag :iso="currentLocale.countryCode" />
          <span :class="responsive ? 'hidden-md-and-down' : 'd-none'" style="margin: 14px">{{
            currentLocale.title
          }}</span>
          <v-icon small v-if="offsetY && arrow">mdi-chevron-down</v-icon>
          <v-icon small v-if="offsetX && arrow">mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <v-list class="ma-0 pa-0" tile dense>
        <v-list-item v-for="(item, index) in languageList" :key="index" class="ma-0 pa-0">
          <v-btn text block class="ma-0 pa-3 d-flex" @click="changeLocale(item)">
            <span class="mx-1">{{ item.title }}</span>
            <flag :iso="item.countryCode" />
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import config from "@/config.js";
import { updateUserProfile } from "../../services/authService";
export default {
  data: () => ({
    languageList: [
      { title: "English", countryCode: "us", locale: "en", value: 0 },
      { title: "繁體中文", countryCode: "hk", locale: "zhHant", value: 1 },
      { title: "简体中文", countryCode: "cn", locale: "zhHans", value: 2 }
    ],
    loading: false
  }),
  methods: {
    async changeLocale(item) {
      const loggedIn = this.$session.exists("token");
      if (loggedIn) {
        this.loading = true;
        const token = this.$session.get("token");
        const payload = {
          language: item.value
        };

        const result = await updateUserProfile(this.$axios, config, payload, token);
        if (result.status === 200) {
          this.$emit("close");
          this.$i18n.locale = item.locale;
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.state = true;
          this.messageBox.cssClass = "error";
          this.messageBox.destination = "";
        }
        this.loading = false;
      } else {
        this.$i18n.locale = item.locale;
      }
      this.$store.commit("setBrowserLanguage", false);
    }
  },
  props: {
    responsive: {
      default: true,
      type: Boolean
    },
    offsetX: {
      default: false,
      type: Boolean
    },
    offsetY: {
      default: true,
      type: Boolean
    },
    arrow: {
      default: true,
      type: Boolean
    }
  },
  computed: {
    currentLocale() {
      return this.languageList.find((elem) => elem.locale === this.$i18n.locale);
    }
  }
};
</script>
