<template>
  <div>
    <v-app-bar app color="white" height="60px" :flat="flat" style="max-width: 100vw">
      <!-- <v-autocomplete
          dense
          rounded
          filled
          hide-details
          hide-no-data
          hide-selected
          prepend-inner-icon="mdi-magnify"
          v-model="searchField"
          class="ml-5"
          height="40px"
          v-if="$screen.width > 600"
          :items="sitePaths"
          item-text="name"
          item-value="path"
          @blur="searchField = ''"
          @click="$router.push(searchField)"
        >w
          <template v-slot:item="data">
            <template>
              <v-list-item-content v-text="data.item.name" @click="$router.push(data.item.path)"></v-list-item-content>
            </template>
          </template>
        </v-autocomplete> -->

      <v-btn
        depressed
        color="primary"
        height="100%"
        tile
        class="ma-0"
        @click="drawer = !drawer"
        v-if="!$store.state.backDestination.show && showDrawerMenu && !drawer"
      >
        <v-icon> mdi-menu </v-icon>
      </v-btn>
      <v-btn
        tile
        depressed
        text
        style="height: 100%; padding: 0 0 0 10px"
        :style="{ padding: $screen.width > 600 ? '0 32px 0 32px' : '' }"
        color="primary"
        @click="back"
        v-if="$store.state.backDestination.show"
        data-cy="platformBack"
      >
        <v-icon left> mdi-chevron-left </v-icon>
        <span class="font-weight-bold" style="font-size: 0.875rem; font-weight: 600">{{
          $store.state.backDestination.name
        }}</span>
      </v-btn>
      <div v-if="showLogo && !drawer">
        <div class="d-flex align-center ml-5">
          <v-img src="@/assets/logo_cropped.png" max-width="35px" />
          <h3 class="ma-0 pa-0" style="white-space: nowrap; cursor: pointer" @click="$router.push('/').catch(() => {})">
            <span style="font-weight: bold">{{ $t("tectechainName") }}</span>
          </h3>
          <v-divider vertical inset style="margin: 0 5px 0 30px" class="hidden-md-and-down" />
        </div>
      </div>
      <home-buttons style="padding: 0px" class="hidden-md-and-down" />
      <v-spacer></v-spacer>
      <language-switch :responsive="false" :arrow="false" />
      <v-toolbar-items class="hidden-sm-and-down" style="margin-left: 10px" v-if="!isLogin">
        <v-btn tile class="primary" depressed to="/login" v-on:click="disableTab" min-width="90px">
          <span style="font-weight: 600">{{ $t("login") }}</span>
        </v-btn>
        <v-btn
          tile
          class="primary"
          depressed
          to="/signup"
          v-on:click="disableTab"
          style="margin-left: 10px"
          min-width="90px"
        >
          <span style="font-weight: 600">{{ $t("signup") }}</span>
        </v-btn>
      </v-toolbar-items>

      <!-- <div class="pnAccount">
          <v-chip class="mx-5" color="primary" text-color="white">
            <v-avatar left>
              <v-icon>mdi-circle-multiple-outline</v-icon>
            </v-avatar>
            <span class="hidden-xs-only">PN Account Balance:</span>
            {{ balance }}
          </v-chip>
        </div> -->

      <div style="display: flex; align-items: center">
        <v-tooltip bottom class="hidden-xs-only">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              @click="refreshPage"
              v-on="on"
              v-bind="attrs"
              width="40px"
              height="40px"
              style="margin-right: 6px"
              class="hidden-xs-only"
              v-if="isLogin"
            >
              <img src="@/assets/zeplin/refresh-1.svg" alt="" />
            </v-btn>
          </template>
          <span>{{ $t("navTopReload") }}</span>
        </v-tooltip>
        <v-tooltip bottom class="hidden-xs-only">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-on="on"
              v-bind="attrs"
              width="40px"
              height="40px"
              to="/platform/notifications"
              class="hidden-xs-only"
              v-if="isLogin"
            >
              <v-badge overlap color="red" :content="notificationCount" :value="notificationCount">
                <img src="@/assets/icon_pack/24px/icon/menu/notifications.svg" alt="refresh" />
              </v-badge>
            </v-btn>
          </template>
          <span>{{ $t("navTopNotification") }}</span>
        </v-tooltip>
        <v-btn text color="primary" @click="logout" class="hidden-xs-only mr-3" v-if="isLogin">
          <span class="font-weight-bold">{{ $t("navTopLogout") }}</span>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app class="elevation-3" :temporary="!isPlatform">
      <v-list flat>
        <div class="companyLogo" style="cursor: pointer" @click="$router.push('/').catch(() => {})">
          <v-img src="@/assets/logo_cropped.png" max-width="35px" />
          <h3>
            <span style="font-weight: bold">{{ $t("tectechainName") }}</span>
          </h3>
        </div>

        <v-divider v-if="isLogin"></v-divider>
        <div
          class="profileAvatar"
          @click="$router.push('/platform/profile').catch((e) => e)"
          style="cursor: pointer"
          v-if="isLogin"
        >
          <v-list-item two-line style="padding-left: 26px" v-if="isLogin">
            <v-list-item-avatar width="46px" height="46px">
              <avatar-from-file-descriptor
                :fileDescriptor="this.$store.getters.profileImage"
                :userid="this.$store.state.userProfile.userid"
                :key="this.$store.getters.profileImage.fileName"
                :size="46"
              ></avatar-from-file-descriptor>
            </v-list-item-avatar>

            <v-list-item-content>
              <div style="margin-bottom: 5px; font-weight: 600; display: flex; align-items: center">
                <h5 style="font-size: 14px; margin-right: 4px">{{ userProfile.userid }}</h5>
                <img
                  src="@/assets/zeplin/icon-premium.png"
                  srcset="@/assets/zeplin/icon-premium@2x.png 2x, @/assets/zeplin/icon-premium@3x.png 3x"
                  class="iconPremium"
                  v-if="$store.state.userProfile.isPremium"
                />
              </div>

              <p style="font-size: 11px">{{ userProfile.email }}</p>
            </v-list-item-content>
          </v-list-item>
        </div>

        <v-list-item-group v-if="isLogin">
          <v-list-item
            v-for="(item, i) in sidebar"
            :key="i"
            link
            :to="item.src"
            @click="item.click"
            active-class="border"
            class="noBorder"
            :ripple="false"
          >
            <v-list-item-action style="margin-right: 14px">
              <v-badge v-if="item.src === '/platform/chat'" dot color="red" :value="newChatMessages.length">
                <img width="24" height="24" :src="item.icon" />
              </v-badge>
              <img width="24" height="24" :src="item.icon" v-else />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-subtitle v-text="item.label"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider v-if="isLogin"></v-divider>
        <v-list active-class="borderProfile" class="noBorderProfile" v-if="isLogin">
          <v-list-group v-for="(item, i) in userSettings" :key="i" :to="item.src" v-model="expandProfileMenu">
            <template v-slot:activator>
              <v-list-item-action style="margin-right: 14px">
                <img width="24" height="24" :src="item.icon" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-subtitle v-text="item.label"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template v-slot:appendIcon>
              <v-icon>mdi-menu-down</v-icon>
            </template>
            <v-list-item-group>
              <v-list-item
                v-for="item in item.subgroup"
                :key="item.key"
                link
                :class="{ subgroup: true, 'pa-0': true, selectedSubgroup: item.key === profileTab }"
                :to="item.src"
                @click="changeTab(item.key)"
                :ripple="false"
              >
                <v-list-item-content style="padding-left: 12px">
                  <v-list-item-subtitle v-text="item.label"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
        </v-list>
        <v-list v-if="isLogin">
          <v-list-item-group>
            <v-list-item
              link
              v-if="$screen.width < 600"
              @click="logout"
              active-class="border"
              class="noBorder"
              :ripple="false"
            >
              <v-list-item-action style="margin-right: 14px">
                <img width="24" height="24" style="padding: 2px" src="@/assets/icon_pack/40px/icn_logout_v1.svg" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t('navTopLogout')"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider></v-divider>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in homeButtons"
            :key="i"
            link
            :to="item.src"
            @click="item.click"
            active-class="border"
            class="noBorder"
            :ripple="false"
          >
            <v-list-item-action style="margin-right: 14px">
              <v-badge v-if="item.src === '/platform/chat'" dot color="red" :value="newChatMessages.length">
                <img width="24" height="24" :src="item.icon" />
              </v-badge>
              <img width="24" height="24" :src="item.icon" v-else />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-subtitle v-text="item.label"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider></v-divider>

        <div v-if="AN">
          <v-divider class="mt-2"></v-divider>
          <p class="mt-3 ml-5 mb-0" style="font-size: 14px">AN admin panel</p>
          <v-list-item-group>
            <v-list-item
              v-for="(item, i) in adminSettings"
              :key="i"
              link
              :to="item.src"
              active-class="border"
              class="noBorder"
            >
              <v-list-item-action>
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-subtitle v-text="item.label"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </div>
      </v-list>
      <template v-slot:append>
        <div>
          <v-footer color="secondary d-flex justify-center">
            <social-buttons
              :size="$screen.width < 600 ? 36 : 24"
              :style="{ margin: '12px', 'margin-bottom': $screen.width < 600 ? '80px' : '12px' }"
            />
          </v-footer>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import LanguageSwitch from "@/components/Common/LanguageSwitch.vue";
import HomeButtons from "./HomeButtons.vue";
import SocialButtons from "@/components/Common/SocialButtons.vue";
import AvatarFromFileDescriptor from "../Common/AvatarFromFileDescriptor.vue";

export default {
  props: {
    flat: {
      type: Boolean,
      default: true
    },
    showDrawerMenu: {
      type: Boolean,
      default: false
    },
    showLogo: {
      type: Boolean,
      default: true
    }
  },
  components: {
    LanguageSwitch,
    HomeButtons,
    SocialButtons,
    AvatarFromFileDescriptor
  },
  data: () => ({
    refreshLogin: false,
    drawer: false,
    expandProfileMenu: false
  }),
  methods: {
    back() {
      this.$router.push(this.$store.state.backDestination.path);
    },
    clickDrawer(val) {
      this.$emit("input", val);
    },
    logout: function () {
      const logoutFunction = () => {
        this.$store.dispatch("clearImageURL");
        this.$session.destroy();
        this.$store.commit("addr", null);
        this.$store.commit("changeState", false);
        this.$store.commit("refreshUserProfile", {});
        localStorage.removeItem("workLastUpdate");
        localStorage.removeItem("redirect");
        this.$store.dispatch("resetState");
        this.$store.commit("logout");
        this.drawer = false;
        this.refreshLogin = !this.refreshLogin;
      };
      if (this.$route.path === "/") {
        logoutFunction();
      } else {
        this.$router.push("/", logoutFunction);
      }
    },
    refreshPage() {
      this.$router.go();
    },
    disableTab() {
      this.$store.commit("updateCurrentPage", null);
    },
    changeTab(key) {
      this.$store.commit("profilePageChange", key);
    },
    clearChatMessages() {
      this.expandProfileMenu = false;
      this.$store.commit("profilePageChange", null);
      const token = this.$session.get("token");
      this.newChatMessages.forEach((elem) => {
        const payload = JSON.stringify({ action: 1, token: token, id: elem.id });
        this.$emit("sendConnection", payload);
      });
    }
  },
  computed: {
    showHomeButtons() {
      return this.$route.path !== "/isLogin" && this.$route.path !== "/signup";
    },
    notificationCount() {
      return this.$store.state.notifications.filter((e) => {
        return e.notificationType !== 20 && e.isNew;
      }).length;
    },
    isLogin() {
      this.refreshLogin;
      return !!this.$session.get("token");
    },
    isPlatform() {
      return this.$route.path.substr(0, 9) === "/platform";
    },
    navbarArray() {
      const content = this.$t("navbar");
      const result = content.replace(/['"]+/g, "").replace(/['“]+/g, "").replace(/['”]+/g, "").split(",");
      return result;
    },
    sidebar() {
      return [
        {
          src: "/platform/bazaar",
          icon: require("@/assets/icon_pack/24px/icon/menu/dashboards_active.svg"),
          label: this.$t("navBazaar"),
          click: () => {
            this.expandProfileMenu = false;
            this.$store.commit("profilePageChange", null);
          }
        },
        {
          src: "/platform/mycollection",
          icon: require("@/assets/icon_pack/24px/icon/menu/mycollection.svg"),
          label: this.$t("navCollections"),
          click: () => {
            this.expandProfileMenu = false;
            this.$store.commit("profilePageChange", null);
          }
        },
        // {
        //   src: "/platform/chat",
        //   icon: require("@/assets/icon_pack/24px/icon/menu/email.svg"),
        //   label: this.$t("navChatroom"),
        //   click: this.clearChatMessages
        // },
        {
          src: "/platform/notifications",
          icon: require("@/assets/icon_pack/24px/icon/menu/chat.svg"),
          label: this.$t("navNotification"),
          click: () => {
            this.expandProfileMenu = false;
            this.$store.commit("profilePageChange", null);
          }
        }
      ];
    },
    homeButtons() {
      const homeButtons = [
        {
          src: "/",
          icon: require("@/assets/i-con/Home.svg"),
          label: this.navbarArray[0],
          click: () => {},
          show: true
        },
        {
          src: "/platform/bazaar",
          icon: require("@/assets/icon_pack/24px/icon/menu/dashboards_active.svg"),
          label: this.navbarArray[1],
          click: () => {},
          show: !this.isLogin
        },
        {
          src: "/#service",
          icon: require("@/assets/i-con/Service.svg"),
          label: this.navbarArray[2],
          click: () => {},
          show: true
        },
        {
          src: "/#cases",
          icon: require("@/assets/i-con/Cases.svg"),
          label: this.navbarArray[3],
          click: () => {},
          show: true
        },
        {
          src: "/#FAQ",
          icon: require("@/assets/i-con/FAQ.svg"),
          label: this.navbarArray[4],
          click: () => {},
          show: true
        },
        {
          src: "/about",
          icon: require("@/assets/i-con/About us.svg"),
          label: this.navbarArray[5],
          click: () => {},
          show: true
        },
        {
          src: "/login",
          icon: require("@/assets/i-con/Log in.svg"),
          label: this.$t("login"),
          click: () => {},
          show: !this.isLogin
        },
        {
          src: "/signup",
          icon: require("@/assets/icon_pack/24px/icon/menu/User Profile.svg"),
          label: this.$t("signup"),
          click: () => {},
          show: !this.isLogin
        }
      ];

      return homeButtons.filter((elem) => elem.show);
    },
    userSettings() {
      return [
        {
          src: "/platform/profile",
          icon: require("@/assets/icon_pack/24px/icon/menu/User Profile.svg"),
          label: this.$t("navProfile"),
          subgroup: [
            {
              src: "/platform/profile",
              icon: require("@/assets/icon_pack/24px/icon/menu/User Profile.svg"),
              label: this.$t("profileNav.Basic"),
              key: "basic"
            },
            {
              src: "/platform/profile",
              icon: require("@/assets/icon_pack/24px/icon/menu/User Profile.svg"),
              label: this.$t("profileNav.Personal"),
              key: "personal"
            },
            {
              src: "/platform/profile",
              icon: require("@/assets/icon_pack/24px/icon/menu/User Profile.svg"),
              label: this.$t("profileNav.Social"),
              key: "social"
            }
          ]
        }
      ];
    },
    adminSettings() {
      return [
        {
          src: "/platform/admin/users",
          icon: "mdi-account-circle",
          label: "Users"
        },
        {
          src: "/platform/admin/works",
          icon: "mdi-fountain-pen",
          label: "Works"
        },
        {
          src: "/platform/admin/events",
          icon: "mdi-calendar-check",
          label: "Events"
        },
        {
          src: "/platform/admin/referral",
          icon: "mdi-qrcode",
          label: "Referral"
        },
        {
          src: "/platform/admin/landing-info",
          icon: "mdi-newspaper",
          label: "Landing Info"
        },
        {
          src: "/platform/admin/orders",
          icon: "mdi-calculator-variant-outline",
          label: "Orders"
        }
      ];
    },
    newChatMessages() {
      let notifications = this.$store.state.notifications.filter((e) => e.notificationType === 20);
      return notifications;
    },
    profileTab: {
      get: function () {
        return this.$store.state.profilePage;
      },
      // setter
      set: function (newValue) {
        this.$store.commit("profilePageChange", newValue);
      }
    },
    AN() {
      const role = this.$session.get("role");
      return role === "AN";
    },
    userProfile() {
      return this.$store.state.userProfile;
    }
  }
};
</script>

<style scoped>
.noBorder {
  border-left: 5px solid #ffffff;
  font-weight: normal;
  padding-left: 26px;
}

.noBorder:hover {
  border-left: 5px solid #e7eaee;
  background-color: #e7eaee;
  font-weight: normal;
}

.border {
  border-left: 5px solid #8bb35d;
}

.border .v-list-item__content {
  font-weight: 600;
}

.border:hover {
  border-left: 5px solid #8bb35d;
}

.noBorderProfile {
  border-left: 5px solid #ffffff;
  padding-left: 10px;
  font-weight: normal;
}

.borderProfile {
  border-left: 5px solid #8bb35d;
}

.subgroup {
  left: 32px;
  border-left: 1px solid #f5f5f5;
}
.subgroup:hover {
  background-color: #e7eaee;
}

.selectedSubgroup .v-list-item__subtitle {
  color: #63a3c3;
  font-weight: 600;
}
</style>
