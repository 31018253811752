<template>
  <div>
    <v-avatar :size="size" :key="this.fileDescriptor.filename" :class="{ grey: true, 'lighten-2': !noImage }">
      <v-img
        :src="src"
        :lazy-src="lazySrc"
        alt=""
        aspect-ratio="1"
        v-if="!noImage"
        :class="{ grey: true, 'lighten-2': !noImage }"
      />
      <!-- <h3 style="font-size: 24px; font-weight: 600" class="white--text ma-0" v-if="noImage">{{ useridSubstring }}</h3> -->
      <v-icon v-if="noImage" color="white" size="30">mdi-account</v-icon>
    </v-avatar>
  </div>
</template>

<script>
import config from "@/config";
import { downloadImageFromBFS } from "@/services/fileService";
export default {
  props: ["fileDescriptor", "userid", "size"],
  data: function () {
    return { src: null, lazySrc: null, noImage: true };
  },
  watch: {},
  created() {
    this.initialise();
  },
  methods: {
    async initialise() {
      this.countDownTimer();
      if (this.$store.state.avatarImage) {
        this.src = this.$store.state.avatarImage;
        this.noImage = false;
      } else {
        if (this.fileDescriptor === "No image" || !this.fileDescriptor || this.fileDescriptor.fileid === "") {
          this.loadingError = true;
          this.noImage = true;
          return;
        }

        if (Object.hasOwnProperty.call(this.fileDescriptor, "thumbnail")) {
          this.thumbnailExist = true;
        }
        if (this.thumbnailExist) {
          downloadImageFromBFS(this.$axios, config, this.fileDescriptor.thumbnail).then(async (res) => {
            if (res.status === 200) {
              this.lazySrc = res.data;
              this.$store.commit("setAvatar", res.data);
              this.noImage = false;
              this.src = res.data;
            } else {
              this.noImage = true;
              this.loadingError = true;
            }
          });
        }

        if (!this.thumbnail || !this.thumbnailExist) {
          downloadImageFromBFS(this.$axios, config, this.fileDescriptor).then(async (res) => {
            if (res.status === 200) {
              this.noImage = false;
              this.src = res.data;
              this.$store.commit("setAvatar", res.data);
            } else {
              this.noImage = true;
              this.loadingError = true;
            }
          });
        }
      }
    },

    resizeBase64Img(dataURL, maxWidth, maxHeight) {
      return new Promise((done) => {
        var img = new Image();
        img.onload = () => {
          var scale, newWidth, newHeight, canvas, ctx;
          if (img.width < maxWidth) {
            scale = maxWidth / img.width;
          } else {
            scale = maxHeight / img.height;
          }
          newWidth = img.width * scale;
          newHeight = img.height * scale;
          canvas = document.createElement("canvas");
          canvas.height = newHeight;
          canvas.width = newWidth;
          ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
          done(canvas.toDataURL());
        };
        img.src = dataURL;
      });
    },
    countDownTimer() {
      if (this.time < 100) {
        setTimeout(() => {
          this.time += 1;
          this.countDownTimer();
        }, 10);
      } else {
        this.time = 0;
      }
    }
  },
  computed: {
    useridSubstring() {
      try {
        let userid = this.$store.state.userProfile.userid;
        return userid.toString().substring(0, 2).toUpperCase();
      } catch {
        return null;
      }
    }
  }
};
</script>
